// src/components/DashboardContent.js
import React from 'react';
import { Navbar } from './navbar';
import "./Dashboard.css";
const Dashboard = () => {
  return (
    <div className="content">
        <Navbar/>
      <h1 className='text-center'>Welcome to Your Dashboard</h1>
      <div className="widgets text-center">
        <div className="widget">
          <h3>SMS</h3>
          <p>show all Sms</p>
        </div>
        <div className="widget">
          <h3>Data</h3>
          <p>Show all Data</p>
        </div>
        <div className="widget">
          <h3>Number</h3>
          <p>Show Number</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
