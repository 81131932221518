// components/Datashow.js
import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { dbRealtime } from './firebase'; // Import the Realtime Database
import { Navbar } from './navbar';
import "./Show.css";

function Showsms() {
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    document.title = "Page";
  }, []);

  useEffect(() => {
    const dataRef = ref(dbRealtime, 'ReceivedSMS'); // Reference to the 'data' path in your database

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setTableData(dataObj);
      } else {
        console.log('No data available.');
      }
    };

    const dataListener = onValue(dataRef, handleDataChange);

    return () => {
      dataListener(); // Unsubscribe the listener
    };
  }, []);

  const reversedTableData = Object.entries(tableData).reverse();

  return (
    <div>
        <Navbar/>
      <h1 style={{ textAlign: 'center', marginTop:'-5px', fontSize:'25px' }}>SMS</h1>
      <p style={{ textAlign: 'center', color: 'red', padding: '0px', marginTop: '-20px' }}>show here</p>
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {reversedTableData.map(([key, value], index) => (
          <div key={index} className='box'>
            {Object.entries(value).map(([dataKey, dataValue], dataIndex) => (
              <p key={dataIndex}>
                {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}: {dataValue}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Showsms;
